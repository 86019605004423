import React from 'react';
import styled from "styled-components";
import theme from "styles/style";
import LinkArrow from "./LinkArrow";

export const DivGoProjects = styled.div`
  display: inline-block;
  position: relative;
  right: ${theme.spacingL};
  @media(max-width: 800px){
    align-self: flex-end;
  }
  @media(max-width: 993px){
    right: 0;
  }
`

export const GoProjects = () => {
    return (
        <DivGoProjects>
            <LinkArrow text={"Ver todos los proyectos"} to={"/nuestro-trabajo/"} color={theme.blue}  />
        </DivGoProjects>
    );
};

export default GoProjects;