import * as React from "react";
import Helmet from "react-helmet";

import {
  address,
  contact,
  foundingDate,
  legalName,
  logo,
  socialLinks,
  siteUrl,
} from "../../../data/config";

const HomeMarkupSchema: React.FC = () => {
  const url = siteUrl;
  const markup = `{ 
		"@context": "http://schema.org",
		"@type": "Organization",
		"legalName": "${legalName}",
		"url": "${url}",
		"logo": "${logo}",
		"foundingDate": "${foundingDate}",
		"founders": [
		  {
			  "@type": "Person",
			  "name": "Rubén Molledo"
			},
		  {
			  "@type": "Person",
			  "name": "Luis Cuartero"
			},
			{	
			"@type": "Person",
			  "name": "Pablo Antonio Borque"
			}
		],
		"contactPoint": [{
			"@type": "ContactPoint",
			"email": "${contact.email}",
			"telephone": "${contact.phone}",
			"contactType": "customer service"
		}],
		"address": {
			"@type": "PostalAddress",
			"addressLocality": "${address.city}",
			"addressRegion": "${address.region}",
			"addressCountry": "${address.country}",
			"postalCode": "${address.zipCode}"
		},
		"sameAs": [
			"${socialLinks.twitter}",
			"${socialLinks.facebook}",
			"${socialLinks.linkedin}",
			"${socialLinks.instagram}"
		]
  	}`;
  const professionalServiceMarkup =`
{
  "@context": "https://schema.org",
  "@type": "ProfessionalService",
  "name": "Obtura Audiovisual",
  "image": "${logo}",
  "@id": "${logo}",
  "url": "${url}",
  "telephone": "${contact.phone}",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "${address.streetAddress}",
    "addressLocality": "${address.city}",
    "postalCode": "${address.zipCode}",
    "addressCountry": "ES"

  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    "opens": "09:00",
    "closes": "18:30"
  },
  "sameAs": [
      "${socialLinks.twitter}",
      "${socialLinks.facebook}",
      "${socialLinks.linkedin}",
      "${socialLinks.instagram}"
  ],
  "department": [{
    "@type": "ProfessionalService",
    "name": "Vídeos para eventos",
    "url": "https://obtura.tv/servicios/eventos/"
  },{
    "@type": "ProfessionalService",
    "name": "Spots publicitarios",
    "url": "https://obtura.tv/servicios/spots-publicitarios/" 
  },{
    "@type": "ProfessionalService",
    "name": "Vídeos aéreos con drones",
    "url": "https://obtura.tv/servicios/video-drones-aereo/"
  },{
    "@type": "ProfessionalService",
    "name": "Fotografía de empresa",
    "url": "https://obtura.tv/servicios/fotografia-empresa/"
  },{
    "@type": "ProfessionalService",
    "name": "Vídeos corporativos para empresas",
    "url": "https://obtura.tv/servicios/video-corporativo/"
  },{
    "@type": "ProfessionalService",
    "name": "Videomarketing creativo",
    "url": "https://obtura.tv/servicios/videomarketing/"
  },{
    "@type": "ProfessionalService",
    "name": "Vídeos submarinos acuáticos",
    "url": "https://obtura.tv/servicios/video-submarino/"
  }]
}
  	`;
  return (
    <Helmet>
      <script type="application/ld+json">{markup}</script>
      <script type="application/ld+json">{professionalServiceMarkup}</script>
    </Helmet>
  );
};

export default HomeMarkupSchema;
