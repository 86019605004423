import React from 'react';
import team from "./assets/team.jpg";
import teamWebp from "./assets/team.webp";
import {Secction1} from "./occss-home";
import theme from "styles/style";
import LinkArrow from "../../ui/atoms/LinkArrow";

export const HomeS1 = () => {
    return (
        <Secction1 className="container Home__S1 padding-xl" id={"OBTURA-CREACIÓN-AUDIOVISUAL"}>
            <div >
                <h1 className={"HomeS1__m-title putDot--orange"}> OBTURA CREACIÓN <br/>AUDIOVISUAL</h1>
                <div className={"HomeS1__m-text"} >
                <p>
                    Obtura Creación Audiovisual es una productora que, como más o menos
                    ya deja sospechar el nombre, se dedica al marketing audiovisual. Estamos especializados en vídeo y fotografía. Nuestra base está en Zaragoza, aunque no nos gusta poner fronteras.
                </p><p>
                    Trabajamos codo con codo con nuestros clientes para que vean hacerse realidad sus necesidades audiovisuales. Seas una empresa o un particular,
                    tienes nuestras puertas abiertas.
                </p>
                </div>
            </div>
            <div className={"HomeS1__m-picture"}>
                <picture>
                    <source srcSet={teamWebp} width={"611"} loading={"lazy"} />
                    <img src={team} alt={"Team"} width={"611"} loading={"lazy"} />
                </picture>
            </div>
            <div className={"a-linkContact"}>
                <LinkArrow to={"/quienes-somos/#Quienes-somos"} text={"CONÓCENOS"} color={theme.orange} />
            </div>
        </Secction1>
    );
};

export default HomeS1;