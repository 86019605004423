import React from 'react';
import styled from "styled-components";

export const DivLink = styled.div`
  width: 100%;
  bottom: 0em;
  display: flex;
  align-items: baseline;
  justify-content: center;
  
`


export const ButtonMove = ({isLeft = false}) => {
    return (
        <DivLink>
                { ! isLeft &&
                <svg xmlns="http://www.w3.org/2000/svg" width="26.828" height="26.828" viewBox="0 0 26.828 26.828">
                    <g id="Grupo_13" data-name="Grupo 13" transform="translate(-150.586 1878.414) rotate(-90)">
                        <g id="Grupo_4" data-name="Grupo 4" transform="translate(377 -418)">
                            <line id="Línea_7" data-name="Línea 7" x2="12" y2="12" transform="translate(1476 582)" fill="none" stroke="#e4e4e4" strokeLinecap="round" strokeWidth="2"/>
                            <line id="Línea_9" data-name="Línea 9" x1="12" y2="12" transform="translate(1488 582)" fill="none" stroke="#e4e4e4" strokeLinecap="round" strokeWidth="2"/>
                        </g>
                        <g id="Grupo_5" data-name="Grupo 5" transform="translate(377 -430)">
                            <line id="Línea_7-2" data-name="Línea 7" x2="12" y2="12" transform="translate(1476 582)" fill="none" stroke="#e4e4e4" strokeLinecap="round" strokeWidth="2"/>
                            <line id="Línea_9-2" data-name="Línea 9" x1="12" y2="12" transform="translate(1488 582)" fill="none" stroke="#e4e4e4" strokeLinecap="round" strokeWidth="2"/>
                        </g>
                    </g>
                </svg>


                }
                { isLeft &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.828" height="26.828" viewBox="0 0 26.828 26.828">
                        <g id="Grupo_14" data-name="Grupo 14" transform="translate(177.414 -1851.586) rotate(90)">
                            <g id="Grupo_4" data-name="Grupo 4" transform="translate(377 -418)">
                                <line id="Línea_7" data-name="Línea 7" x2="12" y2="12" transform="translate(1476 582)" fill="none" stroke="#e4e4e4" strokeLinecap="round" strokeWidth="2"/>
                                <line id="Línea_9" data-name="Línea 9" x1="12" y2="12" transform="translate(1488 582)" fill="none" stroke="#e4e4e4" strokeLinecap="round" strokeWidth="2"/>
                            </g>
                            <g id="Grupo_5" data-name="Grupo 5" transform="translate(377 -430)">
                                <line id="Línea_7-2" data-name="Línea 7" x2="12" y2="12" transform="translate(1476 582)" fill="none" stroke="#e4e4e4" strokeLinecap="round" strokeWidth="2"/>
                                <line id="Línea_9-2" data-name="Línea 9" x1="12" y2="12" transform="translate(1488 582)" fill="none" stroke="#e4e4e4" strokeLinecap="round" strokeWidth="2"/>
                            </g>
                        </g>
                    </svg>
                }
        </DivLink>
    );
};

export default ButtonMove;