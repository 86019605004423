import React, {createRef} from 'react';
import Layout from 'components/common/layout/layout';
import { SEO } from 'components/common/SEO';
import Hero from "../components/common/cover/hero";
import HomeS1 from "../components/home/Content/HomeS1";
import Contacto from "../components/common/contacto/Contacto";
import HomeS2 from "../components/home/Content/HomeS2";
import HomeS3 from "../components/home/Content/HomeS3";
import Slider from "../components/common/Slider/Slider";
import {graphql} from "gatsby";
import theme from "styles/style";
import HomeMarkupSchema from "../components/common/SEO/HomeMarkupSchema";

const IndexPage = ({data}) => {
    const start = createRef(null);
    const block_1 = createRef(null);
    const end = createRef(null);


    const adjust = {
        start: {left: 80, color: `${theme.orange}`, size: 400, center: false, animation: false},
        block_1:  {left: 0, color: `${theme.blue}`, size: 300, center: false, animation: false}
    }
    return (
        <>
        <SEO
            title = "Obtura | Productora de vídeo y audiovisual en Zaragoza"
            description= "¿Necesitas un vídeo o una sesión de fotografías? No busques más. En Obtura somos especialistas en creación audiovisual. ¡Mira nuestros proyectos!"
            robots = 'index, follow'
        />
        <HomeMarkupSchema />
        <Layout start={start} block_1={block_1} end={end} adjust={adjust}   >
        <Hero to={"#OBTURA-CREACIÓN-AUDIOVISUAL"} src={data.sanityHeroPages.codeVideo__landscape} vsrc={data.sanityHeroPages.codeVideo__portrait}  title={"Portada"} />
    <div className={"container"} id={"start"} ref={start}></div>
        <HomeS1 />
        <div className={"padding-xl"}>
        <Slider works={data.allSanityPortfolio.edges}/>
    </div>
    <span className={"container"} ref={block_1} id={"block_1"}></span>
        <HomeS2 />
        <HomeS3 />
        <div className={"container padding-xl"} >
        <div className={"container"} ref={end} > </div>
        <Contacto  />
        </div>
        </Layout>
        </>
)
}

export const query = graphql`
    query IndexSliderPageQuery {
        allSanityPortfolio(
            filter: {in_slider: {eq: true}}
            sort: {fields: publishedAt, order: DESC}
        ) {
            edges {
                node {
                    codeVideo
                    title
                    mainImage {
                        ... ImageWithPreview
                    }
                    publishedAt
                    slug {
                        current
                    }
                    categories {
                        title
                    }
                    services {
                        h1__title
                    }
                }
            }
        }
        sanityHeroPages(pages: {eq: "inicio"}) {
            codeVideo__portrait
            codeVideo__landscape
        }
    }
`

export default IndexPage
