import React from 'react';
import ButtonDown from "components/ui/atoms/ButtonDown";
import LazyVideoHero from "../iframe/lazyVideoVimeoHero"

export const Hero = ({src, title, to, vsrc = null}) => {
    return (
        <div className="o-hero">
            <LazyVideoHero src={src} vsrc={vsrc} title={title} />
        <ButtonDown to={to} />
        </div>
    );
};

export default Hero;