import ReactDOM from "react-dom";
import React, {useLayoutEffect, createRef } from "react";
import Link from "gatsby-link";
import SanityImage from "gatsby-plugin-sanity-image";
import {ArticleSlide, DivActionsSlide, SectionSlider } from "./styles-slider";
import ButtonMove from "../../ui/atoms/ButtonMove";
import GoProjects from "../../ui/atoms/GoProject";
import ScrollContainer from 'react-indiana-drag-scroll';

const Slider = ({works}) => {
    let cursor = null;
    let slider = createRef(null);
    let move = 0;
    let moveLong;
    let width;


    useLayoutEffect(()=>{
        cursor = document.getElementById("mainCursor");
    }, [move])

    useLayoutEffect(()=>{
        // width = window.innerWidth;
        function changeWidht(){
            setTimeout(()=>{
                width = document.getElementsByTagName("article")[0].offsetWidth + 40;
                moveLong = width;
            }, 1000)
        }
        changeWidht();
        window.addEventListener("resize", changeWidht);
        return () => {
            window.removeEventListener("resize", changeWidht);
        }
    }, []);


    function biggerCircle() {
       if(cursor) { cursor.classList.add('circle--bigger'); }
    }
    function backCircle(){
        if(cursor) {  cursor.classList.remove('circle--bigger');}
    }

     const next = () => {
        let rdSlider =  ReactDOM.findDOMNode(slider.current);
        move = rdSlider.scrollLeft + moveLong;
        rdSlider.scrollTo(move , 0);

    }
    const back = () => {
        let rdSlider =  ReactDOM.findDOMNode(slider.current);
        move = rdSlider.scrollLeft - moveLong;
        rdSlider.scrollTo(move , 0);
    }

    const works_render = []

    works.forEach((work, index) => {
        works_render.push(
            <div className={"sliderContainer"} key={index} onMouseOver={biggerCircle} onMouseLeave={backCircle}  >
                <ArticleSlide id={work.node.slug.current}>
                    <Link onClick={backCircle}  to={`/nuestro-trabajo/${work.node.slug.current}/`} title={work.node.title}>
                        <SanityImage  {...work.node.mainImage} width={500} height={300} alt={work.node.title} />
                        <h3 className={"h1 slide__title"}>{work.node.title}</h3>
                    </Link>
                        <p key={index} className={"slide__text__categories"}> {work.node.services.h1__title}</p>
                </ArticleSlide>
            </div>
        )
    })



    return(
        <div className="slider-projects">

        <ScrollContainer className="scroll-container"  ref={slider} >
            {/* <SectionWrapper  > */}
                <SectionSlider >
                    {works_render}
                </SectionSlider>
            {/* </SectionWrapper> */}
        </ScrollContainer>
        <div className="flex-space-between slider-footer">
            <DivActionsSlide>
                <span onClick={back}> <ButtonMove isLeft={true}/> </span>
                <span onClick={next}> <ButtonMove /> </span>
            </DivActionsSlide>
            <GoProjects />
        </div>
    </div>
    )
};

export default Slider;