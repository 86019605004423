import React from 'react';
import {Secction2} from "./occss-home";

export const HomeS2 = () => {
    return (
        <Secction2 className="container Home__S2 padding-xl">
            <div className={"HomeS2__m-title"}>
            <h2 className={"h1 "}> IMAGINAMOS<span className="orange" >.</span> CREAMOS
            <span className="blue" >.</span><br/> PRODUCIMOS<span className="green" >.</span></h2>
            </div>
            <div className={"HomeS2__m-text"}>
                <p>
                Imaginamos, creamos y producimos este tipo de contenidos (el más consumido en la actualidad por tus clientes): vídeo corporativo, spots, cobertura de eventos y celebraciones, videoclips musicales, contenido para redes sociales y fotografía de producto o imagen con dron.
                </p><p>
                Pero no queremos hacerlo de la misma forma que lo haría cualquier agencia: te vamos a hacer la mejor propuesta, con una estrategia eficaz y en el formato que tú necesitas. No nos importa que las necesidades sean industriales, comerciales, orientadas al entretenimiento, o más vinculadas al arte. Lo haremos contigo.
                </p>
            </div>
        </Secction2>
    );
};

export default HomeS2;