import React from 'react';
import styled from "styled-components";
import theme from "styles/style";
import Link from "gatsby-link";

export const DivLink = styled.div`
  width: 100%;
  height: 3em;
  bottom: 0em;
  display: flex;
  align-items: baseline;
  justify-content: center;
  svg {
    margin-left: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 80%;
  }
  a{
    text-decoration: none;
    text-transform: uppercase;
    color:  ${props => props.color} ;
    text-shadow: ${theme.backgroundDarkColor}  0.1em 0.1em 0.12em
     ;
  }
`
export const LinkArrow = ({text, to, color, isDown = false}) => {
    return (
        <DivLink color ={color} >
            <Link to={to} >
                {text}
                { ! isDown &&
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                    <g id="Grupo_46" data-name="Grupo 46" transform="translate(-242 -2441)">
                        <g id="Elipse_5" data-name="Elipse 5" transform="translate(242 2441)" fill={color} >
                            <circle cx="21" cy="21" r="21" stroke="none"/>
                            <circle cx="21" cy="21" r="20.5" fill="none"/>
                        </g>

                        <g id="Grupo_16" data-name="Grupo 16" transform="translate(1337.255 3989.052) rotate(-130)">
                            <g id="Grupo_4" data-name="Grupo 4" transform="translate(1853 158.696)">
                                <line id="Línea_7" data-name="Línea 7" x2="6.696" y2="6.696" fill="none" stroke={theme.textColor} strokeLinecap="round" strokeWidth="1"/>
                                <line id="Línea_9" data-name="Línea 9" x1="6.696" y2="6.696" transform="translate(6.696)" fill="none" stroke={theme.textColor} strokeLinecap="round" strokeWidth="1"/>
                            </g>
                            <g id="Grupo_5" data-name="Grupo 5" transform="translate(1853 152)">
                                <line id="Línea_7-2" data-name="Línea 7" x2="6.696" y2="6.696" fill="none" stroke={theme.textColor} strokeLinecap="round" strokeWidth="1"/>
                                <line id="Línea_9-2" data-name="Línea 9" x1="6.696" y2="6.696" transform="translate(6.696)" fill="none" stroke={theme.textColor} strokeLinecap="round" strokeWidth="1"/>
                            </g>
                        </g>
                    </g>
                </svg>
                }
                { isDown &&
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                    <circle id="Elipse_18" data-name="Elipse 18" cx="21" cy="21" r="21"  fill="#e97a4e"/>
                    <g id="Grupo_90" data-name="Grupo 90" transform="translate(-1838.262 -137.263)">
                        <g id="Grupo_4" data-name="Grupo 4" transform="translate(1853 158.696)">
                            <line id="Línea_7" data-name="Línea 7" x2="6.696" y2="6.696" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                            <line id="Línea_9" data-name="Línea 9" x1="6.696" y2="6.696" transform="translate(6.696)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                        </g>
                        <g id="Grupo_5" data-name="Grupo 5" transform="translate(1853 152)">
                            <line id="Línea_7-2" data-name="Línea 7" x2="6.696" y2="6.696" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                            <line id="Línea_9-2" data-name="Línea 9" x1="6.696" y2="6.696" transform="translate(6.696)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                        </g>
                    </g>
                </svg>

                }


            </Link>
        </DivLink>
    );
};

export default LinkArrow;