import styled from 'styled-components';
import theme from 'styles/style.js'
import image from './assets/fondo-material.png';
import imageWebp from './assets/fondo-material.webp';

export const Secction1 = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${theme.tablet} ){
    flex-direction: column;
    align-items: flex-start;
  }
  
  .HomeS1__m-title{
    width: 90%;
  }
  .HomeS1__m-text{
    width: 45%;
    @media ( max-width: ${theme.tablet}  ){
      width: 100%;
    }
  }
  .HomeS1__m-picture{
    position: absolute;
    width: 40%;
    height: auto;
    bottom: 2em;
    right: 10%;
    @media (max-width: ${theme.tablet}  ){
      position: relative;
      width: 100%;
      right: 0;
      bottom: 0;
      picture { width: 100%; }

    }
  }
  .a-linkContact {
    position: absolute;
    bottom:0;
    right: 0;
    @media ( max-width: ${theme.tablet}  ){
      margin-top:0.5em;
      position: relative;
      left: 0;
      
    }
  }
`
export const Secction2 = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 600px;

  @media (max-width: 1100px  ) {
    min-height: 800px;
  }

  @media (max-width: ${theme.pro}  ){
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
  
  .HomeS2__m-title{
    width: 100%;
    height: 100%;
  }
  
  .subtitle {
    margin-top: -1.5em;
    font-size: 0.8em;
  }
  
  .HomeS2__m-text{
    position:absolute;
    top: 14rem;
    right: 0;
    width:50%;
    @media (max-width: 1100px) {
      width: 45%;
    }
    @media (max-width:  ${theme.pro}  ){
      position: relative;
      top: -2em;
      width: 100%;
    }
  }
`
export const CameraBG = styled.section`
  background-image: url(${image});
  background-image: url(${imageWebp});
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 1200px;
  @media (max-width: ${theme.desktop}) {
    background-position: center right;
  }
  @media (max-width: ${theme.pro}) {
  background-size: ${theme.pro};
  padding-top: 300px;
  background-position: top right;
}
`

export const Secction3 = styled.section`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .HomeS3__m-title{  
    width: 100%;
    text-align: right;
    margin: 0 auto;
    margin-top: 1em;
    padding-bottom: 0;

    @media (min-width: 1025px) {
      padding-left: 1rem;
    }
    
    @media (max-width: 1024px){
      text-align: left;
    }
  }
  
  
  .HomeS3__m-text{
    width: 50%;
    @media (max-width: 1024px ){
      position: relative;
      width: 100%;
    }
  }
`