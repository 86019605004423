import React from 'react';
import { Secction3, CameraBG } from "./occss-home";

export const HomeS3 = () => {
    return (
        <CameraBG className="padding-xl">
            <Secction3 className="container Home__S3">
                <div >
                <h2 className={"h1 HomeS3__m-title putDot--blue"}>NUESTROS VALORES</h2>
                </div>
                <div className={"HomeS3__m-text"}>
                    <p>
                        Olvídate de lo más clásico porque nosotros ya lo hemos hecho. Preferimos no pensar en vídeos corporativos hechos con los guiones tradicionales. Estamos convencidos de que hay otras formas de hacerlos y aportar el valor que necesitas. Queremos algo único, fresco y creativo. Y estamos convencidos de que tu empresa también lo quiere.  Pero que no te confundan este tipo de adjetivos: somos profesionales de la fotografía y el vídeo y trabajamos contigo mano a mano. Nos gusta disfrutar de un trato cercano y personalizado.
                    </p><p>
                        <strong>¿Y sabes con lo que más disfrutamos?</strong> Conectando y emocionando con nuestros trabajos, que siempre nacen desde la creatividad y la innovación que llevamos dentro y que nos encanta transmitir.
                    </p>
                </div>
            </Secction3>
        </CameraBG>
    );
};

export default HomeS3;