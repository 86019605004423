import styled from "styled-components";
import theme from "styles/style.js";

export const SectionWrapper = styled.div`
  /* width: 100%; */
  display: block;
  /* overflow-x: hidden;
  overflow-y: visible; */
  scroll-behavior: smooth;
  min-width: ${theme.tablet} ;
`

export const SectionSlider = styled.section`
  height: 100%;
  min-height: 550px;
  // min-height: calc(80vw + 1.18px );
  display: flex;
  align-items: baseline;
  flex-direction: row;
  flex-flow: nowrap;
  @media (max-width: 800px){
    min-height: calc( 80vw / 1.18 );
  }
`
export const ArticleSlide = styled.article`
  display: block;
  margin: 0 0 0 40px;
  width: 80vw;
  max-width: 650px;
  flex:none;
  transform: scale(0.95);
  box-sizing: border-box;
  padding: 0;
  text-transform: uppercase;
  transition:1s;
  &:hover{
    transform: scale(1);
  }
  .slide__title{
    transition:3s;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // width: 650px;
    margin:0;
    // @media (max-width: ${theme.tablet}) {
    //   width: 550px;
    //   white-space: normal;
    //   text-overflow: ellipsis;
    // }
    // @media (max-width: ${theme.device}) {
    //   width: 400px;
    //   text-overflow: ellipsis;
    // }
  }
  .slide__text__categories{
      color: ${theme.textColor};
      text-transform: uppercase ;
      display: block;
  }
  img{
    padding-left: 2.5em;
    transition: 0.5s;
  }
`

export const DivActionsSlide = styled.div`
  display: flex;
  position: relative;
  // bottom: 0;
  // right: ${theme.spacingL};
  // z-index: 1;
  @media(max-width: 800px){
    align-self: flex-start;
  }
  span{
    margin-left:  ${theme.spacing};
    height: 65px;
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    &:hover{
      background-color: ${theme.blue};
    }
  }
`
