import React, {createRef, useEffect, useLayoutEffect, useState, Suspense} from "react";
import styled from "styled-components";
import logo from 'assets/icons/logo.svg';




export const Iframe = styled.iframe`
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
export const IframeV = styled.iframe`
  width: 100vw;
  height: 177.77vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 56.25vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`


export const LazyVideoHero = ( {src, title, vsrc=null }) => {
    const isSSR = typeof window === "undefined"; // hack to ssr and lazy
    const [showVideo, setShowVideo] = useState(false);
    const [showVertical, setShowVertical] = useState(true);
    const [divSize, setDivSize] = useState({
        width: undefined,
        height: undefined,
      });

    const finishLoading = () => {
        setShowVideo(true);
    }
    useLayoutEffect( () => {
        setDivSize({
            width:  window.innerWidth,
            height:  window.innerHeight
        })
        function handleResize() {
            setDivSize({
                width:  window.innerWidth,
                height:  window.innerHeight
            })
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    useEffect(()=>{
        setShowVideo(false)
    },[showVertical])


    useEffect(()=>{
        if(divSize.height < divSize.width) {
            setShowVertical(false);
        } else {
            setShowVertical(true);
        }

    }, [divSize])


    return(
        <div className="video-wrapper">
        {!showVideo &&
            <div className={"fullscreen flex-column flex-center"}>
                <img className="loading" src={logo} alt="Obtura Audiovisual" width="300" height="300"/>
            <p className="loading-text" >Cargando...</p>
            </div>
        }
        { !isSSR && (
        <Suspense fallback={null}>
            { (showVertical && vsrc )  &&
                <IframeV className={showVideo ? 'visibility' : 'noVisibility'} width="315" height="560" src={"https://player.vimeo.com/video/" + vsrc + "?background=true" }
                    title={title} frameBorder="0"
                    scrolling="no"
                    onLoad={()=>{setShowVideo(true)}}
                    allowFullScreen>
                </IframeV>
            }
            { ( ! showVertical ||  vsrc === null ) &&
                <Iframe className={showVideo ? 'visibility' : 'noVisibility'} width="560" height="315" src={"https://player.vimeo.com/video/" + src + "?background=true" }
                    title={title} frameBorder="0"
                    scrolling="no"
                    onLoad={()=>{setShowVideo(true)}}
                    allowFullScreen>
                </Iframe>
            }
        </Suspense>

            )}
        </div>
    );

}

export default LazyVideoHero;